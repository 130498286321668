import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

Vue.use(VueRouter)
function requireAuth(to, from, next){
  // https://tutorialedge.net/projects/building-imgur-clone-vuejs-nodejs/part-5-authentication/
  // https://betterprogramming.pub/secure-aws-api-gateway-with-amazon-cognito-and-aws-lambda-535e7c9ffea1#1834

  var loggedIn = store.getters['cognito/isLoggedIn']
  if (!loggedIn){
    next({
      path: '/auth/signin',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }

}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/jobs',
    name: 'jobs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jobs" */ '../views/JobsView.vue'),
    beforeEnter: requireAuth
  },
   {
      path: '/auth/signin',
      component: () => import('@/views/Signin')
    },
    {
      path: '/auth/signup',
      component: () => import('@/views/Signup')
    },
    {
      path: '/authenticated',
      component: () => import('@/views/Authenticated')
    },
    { path: '*', redirect: '/auth/signin' },
    // https://forum.vuejs.org/t/creating-an-error-page/51367/2
    { 
      path: '/error/:msg', 
      component: () => import('@/views/ErrorView.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
