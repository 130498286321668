/* ToDo:
    When setting `useBuiltIns: 'usage'`, polyfills are automatically imported when needed.
    Please remove the direct import of `@babel/polyfill` or use `useBuiltIns: 'entry'` instead.

import '@babel/polyfill'
*/
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import vuetify from './plugins/vuetify'

/* add icons to the library */
library.add(faFilter)
library.add(faSortAmountDown)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
// Intercept all axios calls
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

//axios.defaults.timeout = 10000;

/* axios.interceptors.request.use(
  config => {
    // const token = this.$store.dispatch('cognito/fetchJwtToken')
    //localStorage.getItem("access_token");
    console.log('intercepted')//
    const token = store.dispatch('cognito/fetchJwtToken')
    console.log(`Token ${token}`)
    if (token) {
     config.headers.common["Authorization"] = token;
    /
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  }, error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:

         //do something
          break;

        case 401:
          alert("session expired");
          break;        case 403:
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
          });           break;        case 404:
          alert('page not exist');
          break;        case 502:
         setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
);
*/