<template>
<!-- App.vue -->
<!-- https://vuetifyjs.com/en/components/application/#default-application-markup -->
  <v-app>
    <!--v-navigation-drawer app-->
    <!-- -->
    <!--/v-navigation-drawer-->
    <v-app-bar
     :dense="true"
      app
      color="secondary"
      dark
    >
      
      <!--v-toolbar-items-->
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
        <v-spacer></v-spacer>
      <!--/v-toolbar-items>
      <v-toolbar-items>
        <div class="flex-grow-1 align-right"></div-->
        <v-btn text to="/jobs">jobs</v-btn>
        <v-btn text to="/about">about</v-btn>
      <!--/v-toolbar-items-->
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main>
       <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>

export default {
  // https://vuetifyjs.com/en/components/app-bars/
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
